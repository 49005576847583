import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "payment", "paymentMethodForm", "stripeId" ]
  static values = {
    key: String,
    clientSecret: String,
    returnUrl: String,
  }

  static appearance = {
    theme: 'night',
    variables: {
      fontSize: "16px",
      lineHeight: "24px",
    }
  }

  connect() {
    this.stripe = Stripe(this.keyValue)
    this.elements = this.stripe.elements({ clientSecret: this.clientSecretValue, appearance: this.appearance })

    this.paymentElement = this.elements.create("payment")
    this.paymentElement.mount(this.paymentTarget);
  }

  async confirm(e) {
    e.preventDefault()

    const { setupIntent, error } = await this.stripe.confirmSetup({
      elements: this.elements,
      redirect: "if_required",
      confirmParams: {
        return_url: this.returnUrlValue
      },
    })

    if (error) {
      console.log("error", error)
    } else if ("succeeded" === setupIntent.status) {
      this.stripeIdTarget.value = setupIntent.payment_method
      this.paymentMethodFormTarget.submit()
    }
  }
}

