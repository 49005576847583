import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["preview", "main", "prevBtn", "nextBtn"]
  static values = { index: { type: Number, default: 0 }, size: { type: Number, default: 0 } }

  connect() {
    if (this.hasMainTarget) {
      this.sizeValue = this.mainTarget.childElementCount
    } else if (this.hasPreviewTarget) {
      this.sizeValue = this.previewTarget.childElementCount
    }
  }

  prev() {
    this.indexValue = this._clamp(this.indexValue - 1)
  }

  next() {
    this.indexValue = this._clamp(this.indexValue + 1)
  }

  setIndex(event) {
    this.indexValue = this._clamp(event.target.dataset.index)
  }

  indexValueChanged() {
    this.scrollIntoView()
    this.setButtonVisibility()
  }

  sizeValueChanged() {
    this.setButtonVisibility()
  }

  scrollIntoView() {
    requestAnimationFrame(() => {
      if (this.hasPreviewTarget) {
        this.previewTarget.scrollLeft = this.previewTarget.clientWidth * this.indexValue
        // this.previewTarget.children[this.indexValue].scrollIntoView()
      }

      if (this.hasMainTarget) {
        this.mainTarget.children[this.indexValue].scrollIntoView()
      }
    })
  }

  setButtonVisibility() {
    this.prevBtnTargets.forEach(el => el.hidden = (0 === this.indexValue))
    this.nextBtnTargets.forEach(el => el.hidden = (this.sizeValue <= this.indexValue + 1))
  }

  _clamp(value) {
    return Math.max(Math.min(value, this.sizeValue - 1), 0)
  }
}
