import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  remove(event) {
    event.stopPropagation()
    this.element.hidden = true

    setTimeout(() => { this.element.remove() }, 1000)
  }
}
