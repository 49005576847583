import PlacesAutocomplete from "stimulus-places-autocomplete"

export default class extends PlacesAutocomplete {
  static targets = ['streetAddress']

  setAddressComponents (address) {
    super.setAddressComponents(address)

    if (this.hasStreetAddressTarget) this.streetAddressTarget.value = `${address.street_number || ''} ${address.route || ''}` || ''
  }
}
