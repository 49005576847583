import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "messages", "messageForm", "messageInput", "threadList", "threadView" ]

  connect() {
    this.scroller = new MutationObserver(_args => this.scrollToBottom())
    this.scroller.observe(this.messagesTarget, { childList: true })
    this.scrollToBottom()
    this.messageInputTarget.focus()
  }

  disconnect() {
    this.scroller.disconnect()
  }

  scrollToBottom() {
    this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight
  }

  resetForm(_event) {
    this.messageFormTarget.reset()
  }
}
