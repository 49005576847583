import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "frame"]

  connect() {
    if (this.hasFrameTarget) {
      this.frameTarget.hidden = false
    }
  }

  hide(event) {
    event.stopPropagation()

    if (this.hasFrameTarget && this.showNextFrame()) {
    }
    else {
      this.modalTarget.hidden = true
    }
  }

  show(event) {
    event.stopPropagation()
    this.modalTarget.hidden = false
  }

  toggle(event) {
    event.stopPropagation()
    this.modalTarget.hidden = !this.modalTarget.hidden
  }

  turboSubmitEnd(event) {
    if (event.detail.success) {
      this.hide(event)
    }
  }

  showNextFrame() {
    const currentFrame = this.frameTargets.find(x => !x.hidden)
    const nextFrame = currentFrame.nextSibling

    if (nextFrame) {
      currentFrame.hidden = true
      nextFrame.hidden = false
      return true
    }

    return false
  }
}
