import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "uploads"]
  static values = { url: String }

  change(_event) {
    Array.from(this.inputTarget.files).forEach(f => this.uploadFile(f))
    this.inputTarget.value = null
  }

  createProgressMeter(file) {
    const container = document.createElement("div")
    container.innerText = file.name
    container.classList = "p-1 text-xs bg-slate-200 border-slate-400 border rounded overflow-hidden"
    const progressMeter = document.createElement("div")
    progressMeter.classList = "w-0 h-full bg-blue-300"

    container.appendChild(progressMeter)
    this.uploadsTarget.appendChild(container)

    return progressMeter
  }

  uploadProgress(progressMeter) {
    return function(e) {
      if (e.lengthComputable) {
        progressMeter.style.width = `${e.loaded / e.total * 100}%`
      }
    }
  }

  uploadComplete(progressMeter) {
    return function(e) {
      progressMeter.parentNode.remove()

      Turbo.renderStreamMessage(e.target.response)
    }
  }

  uploadFile(file) {
    const progressMeter = this.createProgressMeter(file)

    if (!this.hasUrlValue) { return this.inputTarget.form.requestSubmit() }

    const csrfParam = document.querySelector("[name='csrf-param']").content
    const csrfToken = document.querySelector("[name='csrf-token']").content

    const formData = new FormData()
    formData.append("image", file)
    formData.append(csrfParam, csrfToken)
    const xhr = new XMLHttpRequest()

    xhr.upload.addEventListener("progress", this.uploadProgress(progressMeter))
    xhr.addEventListener("load", this.uploadComplete(progressMeter))

    xhr.open("POST", this.urlValue)
    xhr.setRequestHeader("Accept", "text/vnd.turbo-stream.html")
    xhr.send(formData)
  }
}
