import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source"]

  copy(event) {
    event.preventDefault()

    const value = this.value(this.sourceTarget)

    if (navigator.clipboard) {
      navigator.clipboard.writeText(value)
    }
    else {
      const textarea = document.createElement('textarea')
      textarea.value = value
      textarea.style.fontSize = '20px'
      document.body.appendChild(textarea)

      if (navigator.userAgent.match(/ipad|iphone/i)) {
        textarea.setSelectionRange(0, textarea.value.length)
      }
      else {
        textarea.select()
      }

      document.execCommand('copy')
      document.body.removeChild(textarea)
    }

    const notification = document.createElement("div")
    notification.className = "max-w-sm w-full shadow-lg rounded p-4 relative bg-green-400 border-l-4 border-green-700 text-white pointer-events-auto flex"
    notification.textContent = "Link copied!"
    document.getElementById("notifications").appendChild(notification)
    setTimeout(() => notification.remove(), 2000)
  }

  value(source) {
    if ("A" === source.tagName.toUpperCase()) {
      return source.href
    }
    else if ("INPUT" === source.tagName.toUpperCase()) {
      return source.value
    }
  }
}


